exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-details-js": () => import("./../../../src/pages/blog-details.js" /* webpackChunkName: "component---src-pages-blog-details-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-home-2-js": () => import("./../../../src/pages/home-2.js" /* webpackChunkName: "component---src-pages-home-2-js" */),
  "component---src-pages-home-3-js": () => import("./../../../src/pages/home-3.js" /* webpackChunkName: "component---src-pages-home-3-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resetpassword-jsx": () => import("./../../../src/pages/resetpassword.jsx" /* webpackChunkName: "component---src-pages-resetpassword-jsx" */)
}

